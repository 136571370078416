<template>
    <front-layout>
    <div>
        403
    </div>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    export default {
        components: {FrontLayout},
        data: function () {
            return {}
        },
    }
</script>

<style scoped>

</style>
